 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="流水编号">
				<el-input class="el_input" v-model="form.ra_num" placeholder="流水编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="操作人手机">
				<el-input class="el_input" v-model="form.admin_user_tel" type="number" placeholder="操作人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="操作人姓名">
				<el-input class="el_input" v-model="form.admin_user_name" placeholder="操作人姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="充值类型">
				<el-select class="el_input" v-model="form.balance_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="对公余额" value="1"></el-option>
					<el-option label="对私余额" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="流水编号" width="200px">
					<template slot-scope="scope">
						<span class="tab_line_item">{{scope.row.ra_num}}</span>
					</template>
				</el-table-column>
                <el-table-column label="充值时间" width="205px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作人信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.admin_user_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="充值信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.much}}元/{{scope.row.balance_type_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="备注" prop="mark"></el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
	</div>
</template>

<script>
	export default {
		data() {
			return {

				//搜索条件
				form: {				  
					ra_num:'',//流水编号
					admin_user_tel:'',//充值人员手机
					admin_user_name:'',//充值人员姓名
					balance_type:'',//余额类型
					mark:'',//备注
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
			}
		},
		created() {

			//取出参数
			let query=this.$route.query;
			for(var i in query){
				this.form[i]=query[i]
			}
			
			//读取页面数据
			this.get_page_data()
		},
		methods: {

			//清空查询条件
			ser_para_clear(){
				this.form={
					ra_num:'',//流水编号
					admin_user_tel:'',//充值人员手机
					admin_user_name:'',//充值人员姓名
					balance_type:'',//余额类型
					mark:'',//备注
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'recharge_list_shipper',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//下单时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//结算方式
							switch(item.balance_type){
								case '1':item.balance_type_text="对公余额";break;
								case '2':item.balance_type_text="对私余额";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 18%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin-left: 0;
	}
</style>